@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Oswald:wght@700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    font-family: "Montserrat", normal;
  }
}
.bg-banner_who {
  height: 820px;
}

#title-first{
  font-size: 60px;
  padding-top: 120px;
  padding-bottom: 20px;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #666666;
}
.hover-underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #30B0C7;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

#flags #rfs-btn{
  border: none;
  color: #FF9500;
  padding: 10px 10px 10px 40px;
}
#flags #rfs-btn[aria-expanded="false"]::after{
  // border: none;
  border-top: 5px solid #FF9500;
  // padding: 10px 10px 10px 40px;
}
#flags #rfs-btn[aria-expanded="true"]::after{
  // border: none;
  border-top: 0px;
  border-bottom: 5px solid #FF9500;
  // padding: 10px 10px 10px 40px;
}
#flags ul {
  border: 0;
  padding-left: 35px;
  padding-top: 0px;
}


#flags-mobile #rfs-btn{
  border: none;
  color: #FF9500;
  padding: 0px 0px 0px 0px;
}

#flags-mobile ul {
  border: 0;
  padding-left: 0px;
  padding-top: 0px;
  background-color: transparent;
}

#flags-mobile ul li {
  padding-left: 8px;
 
}
#vector-p {
  width: 25px;
  margin-right: 1px;
  margin-left: 1px;
  padding-top: 70px;
  align-items: center;
}


#cards-p {
   width: 130px;
   display: block;
   margin-left: auto;
   margin-right: auto;
}

#title-pr {
  font-size: 23px;
  padding-top: 20px;

}
  
#paragraph-pr {
  font-size: 20px;
  margin-top: 5px;
}
 
#flags-mobile #rfs-btn[aria-expanded="false"]::after{
  // border: none;
  border-top: 5px solid #30B0C7;
  // padding: 10px 10px 10px 40px;
}
#flags-mobile #rfs-btn[aria-expanded="true"]::after{
  // border: none;
  border-top: 0px;
  border-bottom: 5px solid #30B0C7;
  // padding: 10px 10px 10px 40px;
}
#mapa_world {
  width: 70%;
  height: 100%;
}

.App {
  font-family: sans-serif;
  padding: 10px 30px;
  
}

.App > div {
  background-color: transparent;
  color: rgb(22, 13, 13);
  color: #03090e;
  text-align: center;
  
}
.nextArrow {
  position: absolute;
  top: 25.5%;
  z-index: 1;
  float: right;
  right: 0;
  padding-right: 10px;
}
.nextArrow img {
  transform: rotate(180deg);
}
.prevArrow {
  // top: 35%;
  // z-index: 1;
  padding-top: 26px;
  padding-left: 10px;
  float: left;
}


label.MuiFormLabel-root {
  font-size: 20px;
 
}

#form-mui svg{

  fill: #FF9500;
  font-size: 25px;
}

#core {
	bottom: 450px;
  left: 400px;
	animation: pulse4 0.8s infinite;
}
#core:hover {
  animation-play-state: paused;
}
#core-arg {
	bottom: 100px;
  left: 495px;
	animation: pulse4 0.8s infinite;
  
  
}
.ant-modal-content {
  border-radius: 25px;
  width: 353px;
}
@keyframes pulse4 {
  0% {
    transform: scale3d(0, 0, 0);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}


#contain-map {
  position: relative;
  left: 37.45%;
  top: 0;
  // bottom: 33.71%;
  width: 1000.02px;
  height: 885.07px;
  background: rgba(249, 249, 249, 0.85);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 54px;
}
#Card-Style {
  // border: "0px solid #03506f",
  // border-radius: 30px;
  padding: 40px;
  margin: 20px;
  width: 383px;
  height: 275px;
  // backgroundColor: "#ffffff transparent";
  // background-image: url('./images/what_do_we_offer_/Rectangle_5.png');
  background: linear-gradient(116.64deg, rgba(255, 255, 255, 0.4) 13.05%, rgba(255, 255, 255, 0.1) 81.18%);
  box-shadow: 0px 4px 10px -1px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  
  border-radius: 40px;
/* Note: backdrop-filter has minimal browser support */

  border-radius: 11.9572px;
  background-repeat: norepeat;
  background-size:cover;
  overflow:hidden;
  
}

#title-card-flip{
  font-size:31px; 
  margin-top:20px;
}

#who-are-we-pr{
    
  width:670px;
  font-size:24px;
}

#title-our-number{
  font-size:36px;
  width:245px;
}

#pr-our-number{
  font-size: 24px; 
  width:246px;
}

#map-world{
  // border: "0px solid #03506f",
  border-radius: 30px;
  padding: 40px;
  margin: 20px;
  width: 1643.02px;
  height: 885.07px;
  // backgroundColor: "#ffffff transparent";
  background-image: url('./images/our strenght/Mapita_para_facu.svg');
  background-repeat: norepeat;
  background-size:cover;
  overflow:hidden;
  position: absolute;
}

#contain-map-cards{
  width:500px;
  height:440px;
  // padding: 10px;
  padding: 30px;
  
}
#img-check{
  height:42.08px;
  width:42.57px;
  margin:10px;
}

#pr-first-slide{
  font-size: 32px;
  
}

#title-building{
  height:220px; 
  width:700px; 
  font-size:56px; 
  color:'#666666';
}
#modal-map{
  border-radius: 25px;
  width: 353px;
}
#title-name-ceo{
  font-size: 25px;
}

#title-first-slide{
  font-size: 60px;
}
@media screen and (max-width: 800px) and (min-width: 320px) {
    .bg-banner {
      height: 420px;
    }
    #cards-p {
      width: 22%;
      display: block;
      // margin-left: auto;
      // margin-right: auto;
  }
  #title-pr {
    font-size: 15px;
    padding-top: 12px;

  }
  #rfs-btn{
    justify-content: unset;
  }
    
  #paragraph-pr {
    font-size: 12px;
    width: 160px;
    padding: 0px;
    margin: auto;
  }

  #vector-p {
    width: 15px;
    margin-right: 1px;
    margin-left: 1px;
    padding-top: 70px;
    align-items: center;
  }
  #Card-Style{
    width: 166px;
    height: 162px;
    padding: 15px;
    margin: 10px;
  }
  #card-flip{
    width: 243px;
    height: 195px;
  }
  #title-card-flip{
    font-size: 17px;
    margin-top:10px;
  }
  #img-card-flip {
    width: 40%;
  }
  #title-first{
    font-size: 24px;
    padding-bottom: 20px;
    padding-top:25px
  }
  #eclipse-w{
    width: 38px;
    height: 65px;
    // position: relative;
  }

  #who-are-we-pr{
    
    width: 367px;
    font-size: 14px;
  }

  // .App > div {
  //   background-color: transparent;
  //   color: rgb(22, 13, 13);
  //   color: #03090e;
  //   text-align: center;
    
  .slick-slider {
    margin-left: 0px;
    margin-right: 0px;
  }
  
  .nextArrow {
    position: absolute;
    top: 15.5%;
    z-index: 1;
    float: right;
    right: 0;
    width: 30px;
  }
  .nextArrow img {
    transform: rotate(180deg);
  }
  .prevArrow {
    // top: 35%;
    // z-index: 1;
    width: 30px;
    padding-top: 26px;
    float: left;
  }
  #card-flip-pr{
    margin-top: 8px;
  }

  #title-our-number{
    margin-left: auto;
    margin-right: auto;
    font-size:16px;
    width:110px;
  }
  
  #pr-our-number{
    font-size: 14px; 
    width:144px;
    // font-weight: 600;
    line-height: 25px;
    margin-right: 15px;
    margin: auto;
  }
  #col-our-number{
    width: 40%;
    height: 143px;
    margin: 10px;
  }
  .secsion-separator{
    padding-top: 6px;
    // padding-bottom: 25px;
  }
  // #first-slide{
  //   width: 200px;
  //   height: 100px;
  // }
  #map-world{
    width: 100%;
    height: 220.38px;
    position:relative;
    background-repeat: norepeat;
    background-size:cover;
    overflow:hidden;
    margin:0;
  }
  #contain-map{
    position: static ;
    height: 429px;
    left: 2.31%;
    right: 5.31%;
    top: 44.55%;
    bottom: 44.6%;
    width: 100%;
    height: 400px;
    background: rgba(249, 249, 249, 0.7);
    backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 54px;
  }
  #contain-map-cards{
    width: 50%;
    height:200px;
    padding: 2px;
    margin-bottom: 0px;
    line-height: 14px;
  }
  #title-contain-cards{
    margin-bottom: -10px;
    margin-top: -8px;
    font-size:17px;
    padding-bottom: 0px;
  }
  #pr-contain-cards{
    font-size: 10px;
    margin-top:2px;
  }
  #img-check{
    height:26.08px;
    width:27.57px;
    // margin:5px;
  }
  #core-arg{
    width: 11.5px;
    bottom: 424px;
    left: 114px;
    animation: pulse4 0.8s infinite;
  }
  #core{
    width: 11.5px;
    bottom: 520px;
    left: 91px;
    animation: pulse4 0.8s infinite;
  }
  #title-first-slide{
    font-size: 18px;
    padding: 0px;
  }
  #pr-first-slide{
    font-size: 12px;
    margin: 0px;
    margin-right: 0px;
    line-height: 16px;
    

  }
  #first-slide{
    margin: 0px;
    margin-left:15px;
    width: 175px;
  }
  #img-computer{
    // width: 184px;
    width: 178px;
    margin-left: 0px;
    margin-top: 10px;
  }
  #img-computer > img {
    margin-right : 0px;
  }
  #bt-first-slide{
    margin: 0px;
    margin-top:20px;
    border-radius: 1.5rem;
    width: 150px;
    height: 32px;
  }
  #title-building{
    height:117px; 
    width:342px; 
    font-size:32px; 
    margin-top:25px;
    color:'#666666';
  }
  #modal-map {
    border-radius: 25px;
    width: 100px;
  }
  #title-ceo {
    font-size: 25px;
  }
  #pr-leader{
    font-size: 18px;
  }
  #img-ceo {
    width: 100px;
  }
  #title-name-ceo{
    margin-top: 10px;
    font-size: 16px;
  }
  #pr-desc-ceo{
    margin-top: 5px;
    font-size: 14px;
    line-height: 25px;
    width: 275px;
  }
  .ant-modal-content {
    border-radius: 25px;
    width: 300px;
    height: 398px;
    left: 45px;
  }
  .ant-modal-body{
    width: 263px;
    padding-left: 10px;
    padding-right: 10px;

  }
  #col-ceo{
    margin-left: 50px;
  }
  #div-copy{
    height: auto;
  }
  #copyright{
    font-size: 14px;
    width: 76%;
    margin: auto;
  }
  
  
}


// #card-flip div{
//   overflow: hidden;
// }
// .email-frm div {
//   background: transparent;
//   border: 0;
// }

// .prevArrow > span {
//   top: 50%;
// }
// #button-draw {
//   padding-top: 10px;
//   padding-bottom: 10px;
// }

@primary-color: #30B0C7;